@media only screen and (max-width: 1000px) {
    
    /* === Fonts === */

    h1 {
        font-family: superclarendon, serif;
        font-weight: 700;
        font-style: normal;
    }
    
    h2 {
        font-family: cabazon, serif;
        font-weight: 400;
        font-style: normal;
    }
    
    h4 {
        font-family: "Cabazon";
        font-size: 1em;
        margin: 0;
    }
    
    p {
        font-family: "Uncut Sans";
        font-size: 1em;
    }

    /* === About === */

    .about-wrapper {  
        width: calc(100vW - 150px);
    }
      
    .bio-artist-statement-container {
        padding-top: calc(var(--app-height) / 3);
        padding-bottom: calc(var(--app-height) / 3);
        padding-left: 100px;
        width: 80%;
        margin-right: 0px;
    }
      
    .bio-artist-statement {
        width: 75%;
    }
      
    .gradient {
        width: calc(100vW - 150px);
        -webkit-mask-image: linear-gradient(0deg, rgba(0,0,0,1) 70%, rgba(0,0,0,0) 80%);
        -webkit-mask-size: 100% 100%;
        -webkit-mask-repeat: no-repeat;
        -webkit-mask-position: left top, left bottom;
    }
      
    .gradient::-webkit-scrollbar {
        display: none;
    }
      
    .navigation-menu::-webkit-scrollbar {
        display: none;
    }
      
    .about-wrapper h1, p{
        color: var(--bright-yellow);
        position: relative;
        
    }
      
    #symara-logo {
        margin-left: 100px;
        margin-top: 100px;
        width: 200px;
        z-index: 1;
    }
/* === Projects === */

    .projects-wrapper {
        width: calc(100vW - 150px);
    }
    
    .title-year-container {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }
    
    .project-information {
        display: flex;
        justify-content: space-between;
        padding: 10px 20px;
    }
    
    .project-information h3 {
        font-family: "Cabazon";
        font-size: 1.5em;
        margin-bottom: 0;
    }
    
    .description-premier-container {
        width: 50%;
    }
    
    .description-premier-container ul {
        color: var(--main-red);
        font-family: "Uncut Sans Bold";
        padding: 0;
        list-style: none;
    }
    
    .credits-container {
        width: 50%;
    }
    
    .watch {
        right: 75px;
    }
    
    .red-bar {
        background-color: var(--main-red);
        height: 30px;
        width: 100%;
    }
    
    .press-container {
        padding: 30px 20px;
    }
    
    .press-card {
        padding: 10px;
        row-gap: 20px;
        width: 30%;
    }
    
    .press-details {
        display: flex;
        flex-wrap: wrap;
    }
    
/* === Menus === */

    .symara-menu {
        border-left-width: 2px;
        border-right-width: 2px;
        width: 75px;
    }

    .symara-menu h1 {
        font-size: 1.5em;
    }
      
    .contact-wrapper {
        width: calc(100vW - 150px);
    }


    .blue-wrapper {
        border: 2px solid black;
        border-radius: 30px;
        display: flex;
        height: auto;
        width: 100%;
        margin: 10px;
        width: calc(100vW - 150px);
    }
    
    .yellow-wrapper {
        background-color: var(--bright-yellow);
        border-radius: 30px;
        display: flex;
        height: auto;
        margin: 10px;
        width: calc(100vW - 200px);
    }
    
    .sand-wrapper {
        background-color: var(--sand);
        border-radius: 30px;
        display: flex;
        height: auto;
        margin: 10px;
        width: calc(100vW - 220px);
    }
    
    .pink-gradient {
        background: linear-gradient(30deg, var(--bubblegum) 0%, transparent 40%);
        border-radius: 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        height: auto;
        padding: 20px;
        width: calc(100vW - 240px);
    }
    
    #symara-logo-contact {
        height: auto;
        width: 125px;
        z-index: 2;
    }

    .logo-and-title {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 40px 0px;
        width: 100%;
    }


    .logo-and-title > h1 {
        font-size: 1.5em;
        margin-left: 0px;
    }

    .contact-form-wrapper {
        align-items: center;
        display: flex;
        justify-content: center;
        height: auto;
        margin: 0px;
        width: auto;
    }

    .success-form-wrapper {
        margin: 0px;
        height: calc(var(--app-height) - 106px);
        width: 100%;
    }

    .text-input-wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        row-gap: 15px;
        margin: 15px 0px 0px 0px;
        width: auto;
      }

    .contact-menu {
        border-left-width: 2px;
        border-right-width: 2px;
        width: 75px;
    }

    input {
        background: linear-gradient(0deg, var(--bubblegum) 0%, rgba(255, 112, 155, .75) 10%, var(--sand) 90%);
        border: 1px solid var(--bubblegum);
        border-radius: 3px;
        color: var(--main-red);
        font-family: "Uncut Sans";
        padding: 10px 5px;
        width: 100%;
      }

    .contact-menu h1 {
        font-size: 1.5em;
    }
      
    .projects-menu { 
        border-left-width: 2px;
        border-right-width: 2px;
        width: 75px;
    }

    .projects-menu h1 {
        font-size: 1.5em;
    }

/* === Footer === */

    .footer {
        align-items: center;
        border-width: 3px;
        justify-content: center;
    }
    
    .newsletter-form {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        column-gap: 20px;
    }

    .newsletter-block {
        flex-direction: column;
        row-gap: 20px;
        column-gap: 20px;
    }

    iframe {
        border: solid black 2px;
        width: calc(100vW - 150px);
        height: calc((100vW - 150px) * .5625);
    }

    .is-loading {
        width: calc(100vW - 150px);
    }

    #symara-logo-loading {
        width: 150px;
    }

    .image-wrapper { 
        background-position: center;
    }  
    
}

@media only screen and (max-width: 600px) {
    /* === Menus === */

    .symara-menu {
        width: 33px;
    }

    .symara-menu h1 {
        font-size: 1em;
    }
    
    .contact-menu {
        width: 33px;
    }

    .contact-menu h1 {
        font-size: 1em;
    }
    
    .projects-menu { 
        width: 33px;
    }

    .projects-menu h1 {
        font-size: 1em;
    }

    /* === About === */

    .about-wrapper {  
        width: calc(100vW - 74px);
    }


    .bio-artist-statement-container {
        padding-top: calc(var(--app-height) / 3);
        padding-bottom: calc(var(--app-height) / 3);
        padding-left: 10px;
        width: auto;
    }
      
    .bio-artist-statement {
        width: 90%;
    }
      
    .gradient {
        width: calc(100vW - 74px);
    }
      
    .gradient::-webkit-scrollbar {
        display: none;
    }
      
    .navigation-menu::-webkit-scrollbar {
        display: none;
    }
      
    .about-wrapper h1, p{
        color: var(--bright-yellow);
        position: relative;
    }
      
    #symara-logo {
        margin-left: 10px;
        margin-top: 50px;
        width: 200px;
        z-index: 1;
    }

    .reel {
        font-size: 10px;
    }

    .donate {
        font-size: 10px;
    }

    /* === Projects === */

    .projects-wrapper {
        width: calc(100vW - 74px);
    }

    .contact-wrapper {
        width: calc(100vW - 74px);
    }
    
    .blue-wrapper {
        width: calc(100vW - 98px);
    }

    .yellow-wrapper {
        width: calc(100vW - 122px);
    }

    .sand-wrapper {
        width: calc(100vW - 144px);
    }

    .pink-gradient {
        width: calc(100vW - 164px);
    }

    .title-year-container {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }

    .title-year-container > h1 {
        font-size: 2em;
    }

    .title-year-container > h2 {
        font-size: 1.5em;
    }
    
    .project-information {
        flex-direction: column;
        padding: 20px 10px;
    }
    
    .project-information h3 {
        font-family: "Cabazon";
        font-size: 1.5em;
        margin-bottom: 0;
    }
    
    .description-premier-container {
        width: auto;
    }
    
    .description-premier-container ul {
        color: var(--main-red);
        font-family: "Uncut Sans Bold";
        padding: 0;
        list-style: none;
    }
    
    .credits-container {
        align-items: flex-start;
        width: auto;
    }
    
    .watch {
        bottom: -30px;
        right: 10px;
    }

    .watch h1{
        font-size: 3em;
    }
    
    .red-bar {
        background-color: var(--main-red);
        height: 30px;
        width: 100%;
    }
    
    .press-container {
        padding: 30px 20px;
    }
    
    .press-card {
        padding: 10px;
        row-gap: 20px;
        width: 30%;
    }
    
    .press-details {
        display: flex;
        flex-wrap: wrap;
    }

    iframe {
        width: calc(100vW - 78px);
        height: calc((100vW - 78px) * .5625);
    }

    .is-loading {
        width: calc(100vW - 74px);
    }

    #symara-logo-loading {
        width: 100px;
    }
}

@media (hover: hover) {

    .reel h1:hover {
        color: var(--bright-yellow);
        font-family: "Base Neue";
        transition-duration: 300ms;
    }

    .donate h1:hover {
        color: var(--bright-yellow);
        font-family: "Base Neue";
        transition-duration: 300ms;
    }

    .watch h1:hover {
        color: var(--bright-yellow);
        font-family: "Base Neue";
        transition-duration: 300ms;
    }

    .press-card:hover {
        background-color: var(--bright-yellow);
        border: 2px solid var(--blue-blue);
        box-shadow: 5px 5px 5px var(--blue-blue);
        transition-duration: 300ms;
    }

    .symara-menu:hover {
        background-color: var(--bright-yellow);
        color: var(--main-red);
    }

    .projects-menu:hover {
        background-color: var(--main-red);
        color: var(--mustard);
    }
    
    .subscribe-bttn:hover {
        background-color: var(--blue-blue) !important;
        border-color: var(--blue-blue) !important;
        color: var(--mustard) !important;
        transition-duration: 300ms;
    }

    button:hover {
        background: radial-gradient(var(--bubblegum),  rgba(255, 112, 155, 1));
        color: var(--sand);
        transition-duration: 300ms;
    }

    .contact-menu:hover {
        background-color: var(--bright-yellow);
        color: var(--acid-lime) !important;
    }
}