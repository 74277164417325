@font-face {
  font-family: "Uncut Sans";   /*Can be any text*/
  src: local("Uncut Sans"),
    url("./fonts/Uncut-Sans-Regular.otf") format("opentype");
}

@font-face {
  font-family: "Uncut Sans Bold";   /*Can be any text*/
  src: local("Uncut Sans"),
    url("./fonts/Uncut-Sans-Bold.otf") format("opentype");
}

@font-face {
  font-family: "Base Neue";   /*Can be any text*/
  src: local("Base Neue"),
    url("./fonts/BaseNeueTrial-Black.otf") format("opentype");
}

body {
  background-color: var(--sand);
  margin: 0;
}

@keyframes fadeInAnimation {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
   }
}

@keyframes fadeInAndOutAnimation {
  0% {
      opacity: 0;
  }
  50% {
      opacity: 1;
   }
  100% {
    opacity: 0;
 }
}

:root {
  /* === Colors === */
  --bright-yellow: #FAE909;
  --main-red: #FA0909;
  --mustard: #FFC700;
  --acid-lime: #2CB32A;
  --bubblegum: #FF709B;
  --blue-blue: #008DFF;
  --sand: #E6DECE;

  /* === AppHeight === */
  --app-height: 100%;
}

  /* === Fonts === */

h1 {
  font-family: superclarendon, serif;
  font-weight: 700;
  font-style: normal;
}

h2 {
  font-family: cabazon, serif;
  font-weight: 400;
  font-style: normal;
}

h4 {
  font-family: "Cabazon";
  font-size: 1em;
  margin: 0;
}

p {
  font-family: "Uncut Sans";
  font-size: 1em;
}

/* === About === */

.about-wrapper {  
  animation: fadeInAnimation ease 2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-delay: .5s;
  opacity: 0;
  background-color: var(--mustard);
  background-size: cover;
  background-position: center;
  background-repeat: none;
  height: calc(var(--app-height));
  width: calc(100vW - 250px);
  overflow: scroll;
  position: relative;
  transition: width 300ms;
}

.about-wrapper::-webkit-scrollbar {
  display: none;
}

.bio-artist-statement-container {
  padding-top: calc(var(--app-height) / 2);
  padding-bottom: calc(var(--app-height) / 5);
  padding-left: 150px;
}

.bio-artist-statement {
  width: 50%;
}

.gradient {
  background: linear-gradient(30deg, #000 0%, transparent 60%);
  height: calc(var(--app-height));
  left: 0;
  position: relative;
  overflow: scroll;
  top: 0;
  width: calc(100vW - 250px);
  z-index: 0;
  -webkit-mask-image: linear-gradient(0deg, rgba(0,0,0,1) 50%, rgba(0,0,0,0));
  -webkit-mask-size: 100% 100%;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-position: left top, left bottom;
}

.gradient::-webkit-scrollbar {
  display: none;
}

.navigation-menu::-webkit-scrollbar {
  display: none;
}

.about-wrapper h1, p{
  color: var(--bright-yellow);
  position: relative;
  
}

#symara-logo {
  position: absolute;
  margin-left: 150px;
  margin-top: 100px;
  height: auto;
  width: 300px;
  z-index: 1;
}

/* === Contact === */

.contact-wrapper {
  animation: fadeInAnimation ease 2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-delay: .5s;
  opacity: 0;
  overflow: scroll;
  background-color: var(--acid-lime);
  height: var(--app-height);
  display: flex;
  flex-direction: column;
  min-height: var(--app-height);
  transition: width 300ms;
  width: calc(100vW - 250px);
}

.blue-wrapper {
  background-color: var(--blue-blue);
  border: 5px solid black;
  border-radius: 30px;
  display: flex;
  margin: 20px;
  width: calc(100vW - 300px);
}

.yellow-wrapper {
  background-color: var(--bright-yellow);
  border-radius: 30px;
  display: flex;
  margin: 20px;
  width: 100%;
}

.sand-wrapper {
  background-color: var(--sand);
  border-radius: 30px;
  display: flex;
  margin: 20px;
  width: 100%;
}

.pink-gradient {
  background: linear-gradient(30deg, var(--bubblegum) 0%, transparent 40%);
  border-radius: 30px;
  padding: 20px;
  width: 100%;
}

#symara-logo-contact {
  height: auto;
  width: 200px;
  z-index: 2;
}

.logo-and-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.logo-and-title > h1 {
  color: var(--bubblegum);
  font-weight: 800;
  font-size: 2.5em;
  margin: 10px 10px 10px 0px;
}

.contact-form-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  margin: 40px;
  max-width: 600px;
  height: calc(var(--app-height) - 250px);
  width: 60%;
}

.success-form-wrapper {
  align-content: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 40px;
  max-width: 600px;
  height: calc(var(--app-height) - 250px);
  width: 60%;
}

.success-form-wrapper p {
  color: var(--main-red);
}

form {
  display: flex;
  flex-direction: column;
  width: 100%;
}

input {
  background: linear-gradient(0deg, var(--bubblegum) 0%, rgba(255, 112, 155, .75) 10%, var(--sand) 90%);
  border: 1px solid var(--bubblegum);
  box-sizing: border-box;
  border-radius: 3px;
  height: 40px;
  color: var(--main-red);
  font-family: "Uncut Sans";
  padding: 10px 5px;
  width: 250px;
}

input::placeholder {
  color: var(--main-red);
  font-family: "Uncut Sans";
}

input:focus {
  border-bottom: 3px solid var(--main-red);
  outline: none;
}

textarea {
  background: linear-gradient(0deg, var(--bubblegum) 0%, rgba(255, 112, 155, .75) 10%, var(--sand) 90%);
  border: 1px solid var(--bubblegum);
  border-radius: 3px;
  color: var(--main-red);
  font-family: "Uncut Sans";
  height: 200px;
  margin: 15px 0px;
  padding: 10px 5px;
  resize: none;
  width: auto;
}

textarea::placeholder {
  color: var(--main-red);
  font-family: "Uncut Sans";
}

textarea:focus {
  border-bottom: 2px solid var(--main-red);
  outline: none;
}

.text-input-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  row-gap: 15px;
  margin: 15px 0px;
}

button {
  background: radial-gradient(var(--sand),  rgba(255, 112, 155, .75));
  border: 2px solid var(--bubblegum);
  border-radius: 40px;
  color: var(--main-red);
  cursor: pointer;
  font-family: "Base Neue";
  font-size: 1em;
  letter-spacing: 3px;
  padding: 15px 20px;
  text-transform: uppercase;
  transition-duration: 300ms;
  width: 200px;
}

.button-wrapper {
  display: flex;
  justify-content: center;
}

/* === Projects === */

.projects-wrapper {
  animation: fadeInAnimation ease 2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-delay: .5s;
  opacity: 0;
  background-color: var(--mustard);
  color: var(--main-red);
  height: var(--app-height);
  overflow-y: scroll;
  scroll-snap-type: y proximity;
  width: calc(100vW - 250px);
  transition: width 300ms;
}

.projects-wrapper::-webkit-scrollbar {
  display: none;
}

.projects-wrapper::-webkit-scrollbar {
  display: none;
}

.projects-wrapper p {
  color: var(--main-red);
}

.image-wrapper { 
  background-size: cover;
  display: flex;
  height: 80vH;
  position: relative;
  width: 100%;
}  

.image-wrapper h1 { 
  color: var(--bright-yellow);
  font-family: "casey", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 5em;
  margin-left: 20px;
  margin-bottom: 0px;
}

.image-wrapper h2 { 
  color: var(--bright-yellow);
  font-family: "Superclarendon";
  font-size: 2em;
  font-style: italic;
  font-weight: 700;
  margin-top: -10px;
}

.title-year-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-right: 10px;
}

.project-information {
  display: flex;
  justify-content: space-between;
  padding: 30px 20px;
  width: auto;

}

.project-information h3 {
  font-family: "Cabazon";
  font-size: 1.5em;
  margin-bottom: 0;
}

.description-premier-container {
  width: 50%;
}

.description-premier-container ul {
  color: var(--main-red);
  font-family: "Uncut Sans Bold";
  padding: 0;
  list-style: none;
}

.credits-container {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100px;
  width: 30%;
}

.credit-card {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.credit {
  width: 40%;
}

.creditee {
  font-family: "Superclarendon";
  text-transform: uppercase;
  text-align: right;
  width: 60%;
}

.watch {
  bottom: -40px;
  right: 150px;
  text-decoration: none;
  position: absolute;
}

.reel {
  display: inline-block;
  text-decoration: none;
}

.donate {
  display: inline-block;
  text-decoration: none;
}

.reel h1 {
  color: var(--main-red);
  font-family: "Base Neue";
  font-size: 5em;
  margin-bottom: 0px;
  transition-duration: 300ms;
}

.donate h1 {
  color: var(--main-red);
  font-family: "Base Neue";
  font-size: 5em;
  margin-top: 10px;
  transition-duration: 300ms;
}

.watch h1 {
  color: var(--main-red);
  font-family: "Base Neue";
  transition-duration: 300ms;
}

.red-bar {
  background-color: var(--main-red);
  height: 30px;
  width: 100%;
}

.press-container {
  padding: 30px 20px;
}

.project {
  /* scroll-snap-align: start; */
  position: relative;
}

.project-list {
  display: flex;
  flex-wrap: wrap;
  column-gap: 20px;  
  row-gap: 20px;

}

.press-card {
  background-color: var(--sand);
  border: 2px solid var(--main-red);
  border-radius: 20px;
  box-shadow: 5px 5px 5px var(--main-red);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 250px;
  padding: 10px;
  row-gap: 20px;
  text-decoration: none;
  transition-duration: 300ms;
  width: 30%;
}

.press-card h2 {
  font-family: "Uncut Sans Bold";
  letter-spacing: 1px;
  color: var(--main-red);
}

.press-card h3 {
  color: var(--main-red);
}

.press-details {
  display: flex;
}

.press-details h3 {
  font-family: "Uncut Sans";
  font-size: 1em;
  margin-right: 10px;
}

/* === Menus === */

.symara-menu {
  animation: fadeInAnimation ease 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  align-items: center;
  background-color: var(--main-red);
  border-left: 4px solid black;
  border-right: 4px solid black;
  color: var(--bright-yellow);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: var(--app-height);
  justify-content: center;
  overflow: hidden;
  transition-property: background-color, width;
  transition-duration: 300ms;
  width: 117px;
  z-index: 999;
}

.contact-menu {
  animation: fadeInAnimation ease 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  align-items: center; 
  background-color: var(--acid-lime);
  border-left: 4px solid black;
  border-right: 4px solid black;
  color: var(--bright-yellow);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: var(--app-height);
  justify-content: center;
  overflow: hidden;
  transition-property: background-color, width;
  transition-duration: 300ms;
  width: 117px;
  z-index: 999;

}

.projects-menu {
  animation: fadeInAnimation ease 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  align-items: center;
  background-color: var(--mustard);
  border-left: 4px solid black;
  border-right: 4px solid black;
  color: var(--main-red);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: var(--app-height);
  justify-content: center;
  overflow: hidden;
  transition-property: background-color, width;
  transition-duration: 300ms;
  width: 117px;
  z-index: 999;
}

.navigation-menu {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: var(--app-height);
}

.flex {
  display: flex;
}

.footer {
  align-items: center;
  background-color: var(--mustard);
  border-top: 5px solid black;
  border-bottom: 5px solid black;
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: center;
  padding: 30px;
  position: relative;
  row-gap: 20px;
  width: auto;
}

.footer p {
  color: var(--main-red);
  font-family: "Uncut Sans Bold";
  letter-spacing: 1px;
  text-align: center;
}

.footer h3 {
  color: var(--main-red);
  font-family: "Superclarendon";
  letter-spacing: 1px;
}

.footer a {
  color: var(--main-red);
}

.newsletter-form {
  align-items: center;
  align-content: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  row-gap: 20px;
  column-gap: 20px;
}

.newsletter-block {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  column-gap: 20px;
}

.newsletter-form input {
  background: none;
  border-bottom: 3px solid var(--main-red);
  border-left: none;
  border-right: none;
  border-top: none;
  color: var(--main-red);
  font-family: "Uncut Sans";
  padding: 10px 5px;
  width: 250px;
}

input::placeholder {
  color: var(--main-red);
  font-family: "Uncut Sans";
}

.newsletter-form input:focus {
  border-bottom: 3px solid var(--blue-blue);
  color: var(--blue-blue);
  outline: none;
}

textarea {
  background: linear-gradient(0deg, var(--bubblegum) 0%, rgba(255, 112, 155, .75) 10%, var(--sand) 90%);
  border: 1px solid var(--bubblegum);
  box-sizing: border-box;
  color: var(--main-red);
  font-family: "Uncut Sans";
  height: 200px;
  margin: 15px 0px;
  padding: 10px 5px;
  resize: none;
  width: auto;
}

textarea::placeholder {
  color: var(--main-red);
  font-family: "Uncut Sans";
}

textarea:focus {
  border-bottom: 3px solid var(--main-red);
  outline: none;
}

.text-input-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  row-gap: 15px;
  margin: 15px 0px;
}

.subscribe-bttn {
  background-color: var(--main-red) !important;
  border-radius: 20px;
  color: var(--mustard) !important;
  font-family: "Uncut Sans Bold" !important;
  cursor: pointer;
  transition-duration: 300ms;
  text-transform: uppercase;
}

.contact-link {
  text-decoration: none;
}

iframe {
  border: solid black 5px;
  width: calc(100vW - 260px);
  height: calc((100vW - 260px) * .5625);
  margin-bottom: 20vH;
}

.is-loading {
  height: var(--app-height);
  width: calc(100vW - 250px);
  display: flex;
  align-items: center;
  justify-content: center;
}

#symara-logo-loading {
  animation: fadeInAndOutAnimation ease 3s;
  animation-iteration-count: infinite;
  animation-fill-mode: forwards;
  width: 300px;
}

.about-links {
  display: flex;
  flex-direction: column;
}